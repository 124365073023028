import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        return {
            headers: {
            }
        };
    },
    getRecent() {
        return services.http.get(`/v1.0/eventmanager/organizations/recent`, this.getConfig());
    },
    getOwnedEvents() {
        return services.http.get(`/v1.0/eventmanager/organizations`, this.getConfig());
    },
    create(model) {
        return services.http.post(`/v1.0/eventmanager/organizations/`, model, this.getConfig());
    },
    get(id) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}`, this.getConfig());
    },
    put(model) {
        return services.http.put(`/v1.0/eventmanager/organizations/${model.id}`, model, this.getConfig());
    },
    getEvents(id, userCreated) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/events?userCreated=${userCreated||false}`, this.getConfig());
    },

    getLicenseStatus(id) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/license`, this.getConfig());
    },
    getStats(id) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/stats`, this.getConfig());
    },
    getJoinInfo(id, code) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/joininfo?code=${code||''}`, this.getConfig());
    },

    getPermissions(id) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/permissions`, this.getConfig());
    },
    addPermissions(id, profileId) {
        return services.http.put(`/v1.0/eventmanager/organizations/${id}/permissions/${profileId}`, {}, this.getConfig());
    },
    deletePermissions(id, profileId) {
        return services.http.delete(`/v1.0/eventmanager/organizations/${id}/permissions/${profileId}`, this.getConfig());
    },

    getRegistrations(id) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/registrations`, this.getConfig());
    },
    putRegistration(id, code, request) {
        return services.http.put(`/v1.0/eventmanager/organizations/${id}/registrations/${code}`, request, this.getConfig());
    },
    uploadRegistrations(id, model) {
        return services.http.post(`/v1.0/eventmanager/organizations/${id}/upload/registrations`, model, this.getConfig());
    },
    getUser(id, code) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/registrations/${code}`, this.getConfig());
    },

    getMember(id, profileId) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/users/${profileId}`, this.getConfig());
    },
    disconnectMember(id, profileId, deleteResults) {
        return services.http.delete(`/v1.0/eventmanager/organizations/${id}/users/${profileId}?deleteResults=${deleteResults}`, this.getConfig());
    },

    integrationsConfigureApp(id, request) {
        return services.http.post(`/v1.0/eventmanager/organizations/${id}/integrations/app`, request, this.getConfig());
    },
    getOAuthClient(id, provider) {
        return services.http.get(`/v1.0/eventmanager/organizations/${id}/oauthclients/${provider}`, this.getConfig());
    },
    putOAuthClient(id, provider, model) {
        return services.http.put(`/v1.0/eventmanager/organizations/${id}/oauthclients/${provider}`, model, this.getConfig());
    },

    sendInviteEventEmailToCode(id, code) {
        return services.http.post(`/v1.0/eventmanager/organizations/${id}/email/invite/${code}`, {}, this.getConfig());
    },

}